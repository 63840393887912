import './application'
import '~/vendor/themes/soft-ui/javascript/soft-ui-dashboard'
import '~/vendor/themes/soft-ui/javascript/plugins/sweetalert.min'
import '~/vendor/themes/soft-ui/javascript/plugins/choices.min'

// Datatables

import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-bs5'
import 'datatables.net-buttons'
import 'datatables.net-buttons-bs5'

document.querySelectorAll('.datatable').forEach(function(target) {
  let datatable = new DataTable(target, {
    responsive: true,
    serverSide: true,
    ordering: false,
    ajax: target.getAttribute('data-datatable-url')
  });
});

// Update export links when datatables are searched
document.body.addEventListener('change', (e) => {
  if (e.target.matches('.dt-search input[type=search]')) {
    const baseHref = new URL(document.querySelector('.export-link').getAttribute('href'), window.location);
    baseHref.searchParams.set('search[value]', e.target.value);
    document.querySelector('.export-link').setAttribute('href', baseHref.toString());
  }
});

$('body.client-website:not(.cw-new_transactions, .cw-transactions-new, .cw-forward_transactions, .cw-forward_extensions) select:visible:not(.react-select)').livequery(function() {
  var options = {
    searchPlaceholderValue: 'Type here to search...',
    itemSelectText: '',
    removeItemButton: true
  }

  // If the placeholder has no text specified in BackOffice - passed to here as an empty string, then insert generic placeholder
  // Reason: the empty string renders poorly
  var placeholder = $("option[value='']", this)
  if (placeholder.length > 0 && placeholder.text().trim() === '') {
    placeholder.text('Select an option...');
  }

  let choiceSelect = new Choices($(this)[0], options);

  // Needs to be here as choices messes with the dom
  $(this).on('chosen:updated', function() {
    let newValues = [];

    Array.prototype.forEach.call(this.options,(option) => {
      newValues.push({
        value: option.value,
        label: option.innerHTML
      });
    });

    choiceSelect.setChoices(
        newValues,
        'value',
        'label',
        true,
    );
  });
});
